import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {

  connect() {
    this.subscription = consumer.subscriptions.create(
      { channel: "AdministratorNotificationChannel" },
      { connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this) 
      }
    )
  }
  
  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
  
  _connected() {
    console.log("NotificationChannel......connected")
  }
  
  _disconnected() {
    
  }
  
  _received(notification) {
    console.log("notification controller.js -> received(notification)");
    console.log(notification);
    
    if (notification.actions) {
      $('body').toast({
        displayTime: 0,
        title: notification.title,
        message: notification.text,
        classActions: 'bottom attached',
        position: 'bottom right',
        actions: [{
          text: notification.actions.approve.label,
          class: 'green',
          click: function() {
            window.location.href = notification.notifiable_path
          }
        }, {
          text: notification.actions.cancel.label,
          class: 'red',
          click: function() {}
        }]
      })          
    } else if (notification.title) {
      $('body').toast({
        title: notification.title,
        message: notification.text,
        showProgress: 'bottom'
      })      
    } else {      
      $('body').toast({
        message: notification.text,
        showProgress: 'bottom'
      })  
    }
    
    this.updateNotificationCount(notification.unopened_notification_count)
    this.updateNotifications(notification)
  }
  
  updateNotificationCount(count) {
    $(".notification_count").html(count)
  }
  
  updateNotifications(notification) {
    $(".ui.notification_list.list").prepend(notification.view);
  }
}
