window.notifyRecipient = {
  handler: {}
};

notifyRecipient.ready = function() {
  var $checkbox           = $('.ui.checkbox'),
      $dropdown           = $('.ui.dropdown'),
      $notifyClientForm   = $('form.notify-recipient.form'),
      handler  
  ;
  
  handler = {
    init: function() {
      $checkbox.checkbox();
      $dropdown.dropdown();
      $notifyClientForm.form({
        on: 'blur',
        inline: true,
        fields: {
          recipients: {
            identifier: 'recipients',
            rules: [
              {
                type: 'minCount[1]',
                prompt: 'Please select at least one recipient'
              }
            ]
          }
        }
      })
    }
  };
  
  notifyRecipient.handler = handler;
  handler.init();
}