window.adminOrderInterface = {
  handler: {}
};

adminOrderInterface.ready = function() {
  console.log("Inside order interface...");
  var $orderTotalInput        = $(".order-total.input"),
      $orderAccountLimitInput = $(".order-account-limit.input"),
      $calendar               = $(".ui.calendar"),
      $rejectButton           = $(".reject.button"),
      prepare_reject_order    = 'prepare reject order',
      handler
  ;
  
  handler = {
    checkLimit: function(element) {
      console.log("checking limit of the order");      

      let total = $orderTotalInput.val();
      let limit = $orderAccountLimitInput.val();
      
      let $td = $($orderTotalInput.closest("td"))      

      if ($.isNumeric(total) && $.isNumeric(limit)) {
        if (parseFloat(limit) < parseFloat(total)) {
          $td.addClass("error")
        } else {
          $td.removeClass("error")
        }
      } else {
        $td.removeClass("error")
      }      
    },
    checkReadyToSubmit: function() {
      let enabled = false;
      let $lineItems = $(".order-line-item.item")
      let $td = $($orderTotalInput.closest("td"))
      
      Array.from($lineItems).forEach(function(item) {
        if (item.classList.contains("error")) {
          return enabled = true;          
        }
      });
      
      if ($td.hasClass("error")) {
        enabled = true
      }
      
      // console.log("Enabled: " + enabled);
      let $submitButton = $(".ui.order.form .submit.button")
      if (enabled) {
        $submitButton.addClass("disabled");
      } else {
        $submitButton.removeClass("disabled");
      }
      
    },
    readOnly: function() {
      let form = document.querySelector('form#order-form');
      
      // Disable all form elements
      Array.from(form.elements).forEach(formElement => formElement.disabled = true);      
    },
    init: function() {
      $orderTotalInput.on("change", function() {
        handler.checkLimit(this);
        handler.checkReadyToSubmit();        
      });
      $calendar.calendar({
        type: 'date'
      });
      $rejectButton.on("click", function() {
        let locale = document.querySelector('meta[name="locale"]').content
        let clientId = $(this).data("client-id")
        let siteId = $(this).data("site-id")
        let orderId = $(this).data("order-id")
        let quotationId = $(this).data("quotation-id")
        
        console.log(`Client ID: ${clientId}; Site ID: ${siteId}; Quotation ID: ${quotationId}; Order ID: ${orderId}`);
        
        $(this)
          .api({
            action: prepare_reject_order,
            on: 'now',
            dataType: 'script',
            urlData: {
              locale: locale,
              clientId: clientId,
              siteId: siteId,
              orderId: orderId,
              quotationId: quotationId
            },
            onSuccess: function(response, element, xhr) {
              $(".ui.modal").modal("show")
            }
          });
      })
      
      handler.checkReadyToSubmit();
    }
  };
  
  adminOrderInterface.handler = handler;
  handler.init();
}