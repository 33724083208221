window.siteInterface = {
  handler: {}
};

siteInterface.ready = function() {
  console.log("Inside site interface...");
  var $preferredContactCheckbox     = $(".preferred.contact.checkbox"),
  
      api_update_preferred_contact  = 'update preferred contact',
      handler
  ;
  
  handler = {
    init() {
      $preferredContactCheckbox.api({
        action: api_update_preferred_contact,
        method: 'PUT',
        on: 'change',
        beforeSend: function(settings) {
          settings.data = {
            preferred: $(this).checkbox("is checked")
          };
          return settings;
        },
        onSuccess: function(response, element, xhr) {
          $('body').toast({
            message: response.message,
            position: 'top attached',
            class: 'green centered',
            showProgress: 'bottom'
          })
        }
      })      
    }
  };
  
  siteInterface.handler = handler;
  handler.init();
}