import { Controller } from "stimulus"
import Inputmask from "inputmask"

export default class extends Controller {
  static values = { locale: String,
                    clientId: Number,
                    siteId: Number,
                    quotationId: Number,
                    orderId: Number,
                    sectionId: Number,
                    id: Number,
                    editUrl: String
                  }
  static targets = ["addLineItem", "removeLineItem", "lineItem", "form"]
  
  initialize() {
    console.log("Line Item controller initialized...")
    this.localeValue = document.querySelector('meta[name="locale"]').content
    this.editUrlValue = '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/edit'
  }
  
  connect() {
  }
  
  add() {
    console.log("Add line item...")
    let $buttonTarget = $(this.addLineItemTarget)
    $buttonTarget.addClass("loading")
    
    let numOfRows =  $buttonTarget.data("row")
    
    console.log("Line item: " + this.idValue)
    console.log($buttonTarget)
    console.log("Number of row: " + numOfRows)
    
    $buttonTarget
      .api({
        url: '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/line_items/{id}/add_after',
        method: 'POST',
        dataType: "script",
        on: 'now',
        urlData: {
          locale: this.localeValue,
          clientId: this.clientIdValue,
          siteId: this.siteIdValue,
          quotationId: this.quotationIdValue,
          id: this.idValue
        },
        data: {
          num: numOfRows
        },
        onResponse: function(response) {
          return response;
        },
        successTest: function(response) {
          return response.success || false;
        },
        onSuccess: function(response, element, xhr) {
          // when done, removeClass("loading")
          element.removeClass("loading");
        }
      });
  }
  
  remove() {
    console.log("Remove line item...")
    let $buttonTarget = $(this.removeLineItemTarget)
    $buttonTarget.addClass("loading")
    console.log("Line item: " + this.idValue)
    $buttonTarget
      .api({
        url: '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/line_items/{id}',
        method: 'DELETE',
        dataType: 'script',
        on: 'now',
        urlData: {
          locale: this.localeValue,
          clientId: this.clientIdValue,
          siteId: this.siteIdValue,
          quotationId: this.quotationIdValue,
          id: this.idValue
        },
        onResponse: function(response) {
          return response;
        },
        successTest: function(response) {
          return response.success || false;
        },
        onSuccess: function(response, element, xhr) {
          // when done, removeClass("loading")
          element.removeClass("loading");
        }
      });
      
  }

  variantLimit() {
    console.log("Variant limits of the line item")
    let $lineItemTarget = $(this.lineItemTarget)
    let $variantLimitInputs = $lineItemTarget.find(".variant-limit.input")
    let $totalLimit = $lineItemTarget.find(".total-limit.input")
    let total = 0;
    $variantLimitInputs.each(function(index) {
      let variantLimit = $(this).val()
      if ($.isNumeric(variantLimit)) {
        total += parseFloat(variantLimit)  
      }      
    });
    $totalLimit.val(total)    
  }

  variantQuantity() {
    console.log("Variant quantities of the line item")
    let $lineItemTarget = $(this.lineItemTarget)
    console.log($lineItemTarget)
    let $variantQuantityInputs = $lineItemTarget.find(".variant-quantity.input")
    let $quantityInput = $lineItemTarget.find(".quantity.input")
    let $tr = $lineItemTarget
    let sectionId = $tr.data("line-item-section-id-value")
    let quantityTotal = 0
    let hasError = false
    
    $variantQuantityInputs.each(function(index) {
      let variantLimit = $(this).data("limit")
      let quantity = $(this).val();
      // console.log("Quantity --> " + quantity + " | limit: " + variantLimit);
      // console.log(`Ready to compare ${($.isNumeric(quantity) && $.isNumeric(variantLimit))}`)
      if ($.isNumeric(quantity) && $.isNumeric(variantLimit)) {
        quantityTotal += parseFloat(quantity)
        // console.log(`Quantity Total --> ${quantityTotal} | Limit: ${variantLimit}`)
        if (parseFloat(variantLimit) < parseFloat(quantityTotal)) {
          hasError = true
          $(this).parent().addClass("error")
          $(this).parent().append("<i class='red attention icon'></i>")
          $tr.addClass("error")
          if ($("div[data-tab='section-"+ sectionId +"']").find("i.red.attention.icon").length > 0) {
            
          } else {
            $("div[data-tab='section-"+ sectionId +"']").append("<i class='red attention icon'></i>")
          }          
        } else {
          $(this).parent().removeClass("error")
          $tr.removeClass("error")          
        }
      }
    })
    
    if (hasError) {
      $tr.addClass("error")
      $variantQuantityInputs.popup({
        on: 'focus'
      })      
    } else {
      $tr.removeClass("error")
      var attentionIcon = $tr.find(".red.attention.icon")
      attentionIcon.remove()      
    }
    
    $quantityInput.val(quantityTotal)
  }

  optionTypeQuantity() {
    // console.log("Option type quantities of the line item")
    let $lineItemTarget = $(this.lineItemTarget)
    // console.log($lineItemTarget)
    let $optionTypeQuantityInputs = $lineItemTarget.find(".option-type-quantity.input")
    let $quantityInput = $lineItemTarget.find(".quantity.input")
    let $tr = $lineItemTarget
    let sectionId = $tr.data("line-item-section-id-value")
    let quantityTotal = 0
    let hasError = false
    
    $optionTypeQuantityInputs.each(function(index) {
      let optionTypeLimit = $(this).data("limit")
      let quantity = $(this).val();
      if ($.isNumeric(quantity) && $.isNumeric(optionTypeLimit)) {
        quantityTotal += parseFloat(quantity)
        // console.log(`Quantity Total: ${quantityTotal} | Limit: ${optionTypeLimit}`)
        if (parseFloat(optionTypeLimit) < parseFloat(quantityTotal)) {
          hasError = true
          $(this).parent().addClass("error")
          $(this).parent().append("<i class='red attention icon'></i>")
          $tr.addClass("error")
          if ($("div[data-tab='section-"+ sectionId +"']").find("i.red.attention.icon").length > 0) {
            
          } else {
            $("div[data-tab='section-"+ sectionId +"']").append("<i class='red attention icon'></i>")            
          }
        } else {
          $(this).parent().removeClass("error")
          $tr.removeClass("error")
        }
      }
    })
    
    if (hasError) {
      $tr.addClass("error")
      $optionTypeQuantityInputs.popup({
        on: 'focus'
      })
    } else {
      $tr.removeClass("error")
      var attentionIcon = $tr.find(".red.attention.icon")
      attentionIcon.remove()
    }
    
    $quantityInput.val(quantityTotal)
  }

  checkLimit() {
    console.log("checking limit of the line item")
    let $lineItemTarget = $(this.lineItemTarget)
    let $quantityInput = $lineItemTarget.find(".quantity.input")
    let $limitInput = $lineItemTarget.find(".limit.input")
    
    let quantity = $quantityInput.val();
    let limit = $limitInput.val();
    
    let $tr = $($quantityInput.closest("tr"))
    let $submitButton = $(".ui.order.form .submit.button")
    
    if ($.isNumeric(quantity) && $.isNumeric(limit)) {
      if (parseFloat(limit) < parseFloat(quantity)) {      
        $tr.addClass("error")
      } else {
        $tr.removeClass("error")
      }
    } else {
      $tr.removeClass("error")
    }
  }

  variantCalculate() {
    console.log("Variant calculate line item total ....")
    let $lineItemTarget = $(this.lineItemTarget)
    let $quantityInput = $lineItemTarget.find(".quantity.input")
    let $unitPriceInput = $lineItemTarget.find(".unit-price.input")
    let $itemTotal = $lineItemTarget.find(".item-total.input")

    let quantity = $quantityInput.val()
    let unitPrice = $unitPriceInput.val()
    let itemTotal = quantity * unitPrice
    
    $itemTotal.val(itemTotal)
    this.variantQuantity()
    this.calculateSubtotal()
    this.calculateTotal()        
  }

  optionTypeCalculate() {
    console.log("Option type calculate line item total ....")
    let $lineItemTarget = $(this.lineItemTarget)
    let $quantityInput = $lineItemTarget.find(".quantity.input")
    let $unitPriceInput = $lineItemTarget.find(".unit-price.input")
    let $itemTotal = $lineItemTarget.find(".item-total.input")

    let quantity = $quantityInput.val()
    let unitPrice = $unitPriceInput.val()
    let itemTotal = quantity * unitPrice
    
    $itemTotal.val(itemTotal)
    this.optionTypeQuantity()
    this.calculateSubtotal()
    this.calculateTotal()        
  }
    
  calculate() {
    console.log("Calculate line item total ....")
    let $lineItemTarget = $(this.lineItemTarget)
    let $quantityInput = $lineItemTarget.find(".quantity.input")
    let $unitPriceInput = $lineItemTarget.find(".unit-price.input")
    let $itemTotal = $lineItemTarget.find(".item-total.input")

    let quantity = $quantityInput.val()
    let unitPrice = $unitPriceInput.val()
    let itemTotal = quantity * unitPrice
    
    $itemTotal.val(itemTotal)
    this.checkLimit()
    this.calculateSubtotal()
    this.calculateTotal()        
  }
  
  calculateSubtotal() {
    let sectionId = this.sectionIdValue
    let $subTotalInput = $("input[name='section-total-"+ sectionId +"']")
    console.log("Calculate subtotal... " + sectionId)
    let $itemTotals = $("table[data-section-id='"+ sectionId +"'] .item-total.input")
    let total = 0;
    $itemTotals.each(function(index) {
      let itemTotal = $(this).val()
      if ($.isNumeric(itemTotal)) {
        total += parseFloat(itemTotal)  
      }      
    });
    console.log("subtotal: " + total)
    $subTotalInput.val(total)
  }
  
  calculateTotal() {
    console.log("Calculate total....")
    let $itemTotals = $(".ui.order.form .item-total.input")
    let $orderTotal = $(".ui.order.form .order-total.input")
    let total = 0;
    $itemTotals.each(function(index) {
      let itemTotal = $(this).val()
      if ($.isNumeric(itemTotal)) {
        total += parseFloat(itemTotal)  
      }      
    });
    
    $orderTotal.val(total);
    $orderTotal.change(); 
  }
}