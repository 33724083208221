window.quotationInterface = {
  handler: {}
};

quotationInterface.ready = function() {
  console.log("Inside quotation interface...");
  var $calendar                   = $(".ui.calendar"),
      delete_section              = 'delete section',
      new_section                 = 'new section',
      prepare_reject_quotation    = 'prepare reject quotation',
      prepare_rename_section      = 'prepare rename section',
      new_variant                 = 'new variant',
      active_quotation_tab        = '.ui.quotation.tabular.menu .active.item',
      handler
  ;
  
  handler = {
    initSections() {
      $(".quotation.tabular.menu .item").tab();
      
      $(".new.section.item").off("click");  
      $(".new.section.item").on("click", function(event) {
        let locale = document.querySelector('meta[name="locale"]').content
        let clientId = $(this).data("client-id")
        let siteId = $(this).data("site-id")
        let quotationId = $(this).data("quotation-id")
        
        $(this)
          .api({
            action: new_section,
            on: 'now',
            method: 'GET',
            dataType: 'script',
            urlData: {
              locale: locale,
              clientId: clientId,
              siteId: siteId,
              quotationId: quotationId
            },
            onSuccess: function(response, element, xhr) {
              
            }
          })
      })
      
      $(".rename.section.item").on("click", function() {
        let locale = document.querySelector('meta[name="locale"]').content
        let clientId = $(this).data("client-id")
        let siteId = $(this).data("site-id")
        let quotationId = $(this).data("quotation-id")
        let sectionId = $(active_quotation_tab).data("section-id")
        
        console.log("Client ID: " + clientId + "; Site ID: " + siteId + "; Quotation ID: " + quotationId + "; Section ID: " + sectionId);
        
        $(this)
          .api({
            action: prepare_rename_section,
            on: 'now',
            dataType: 'script',
            urlData: {
              locale: locale,
              clientId: clientId,
              siteId: siteId,
              quotationId: quotationId,
              sectionId: sectionId 
            },
            onSuccess: function(response, element, xhr) {
              $(".ui.modal").modal("show")
            }
          });
      })     
      
      $(".new.variant.button").on("click", function() {
        let locale = document.querySelector('meta[name="locale"]').content
        let clientId = $(this).data("client-id")
        let siteId = $(this).data("site-id")
        let quotationId = $(this).data("quotation-id")
        let sectionId = $(active_quotation_tab).data("section-id")
        
        console.log("Client ID: " + clientId + "; Site ID: " + siteId + "; Quotation ID: " + quotationId + "; Section ID: " + sectionId);
        
        $(this)
          .api({
            action: new_variant,
            on: 'now',
            dataType: 'script',
            urlData: {
              locale: locale,
              clientId: clientId,
              siteId: siteId,
              quotationId: quotationId,
              sectionId: sectionId 
            },
            onSuccess: function(response, element, xhr) {
              $(".ui.modal").modal("show")
            }
          });
      })            
      
      $(".reject.button").on("click", function() {
        let locale = document.querySelector('meta[name="locale"]').content
        let clientId = $(this).data("client-id")
        let siteId = $(this).data("site-id")
        let quotationId = $(this).data("quotation-id")
        
        console.log(`Client ID: ${clientId}; Site ID: ${siteId}; Quotation ID: ${quotationId}`);
        
        $(this)
          .api({
            action: prepare_reject_quotation,
            on: 'now',
            dataType: 'script',
            urlData: {
              locale: locale,
              clientId: clientId,
              siteId: siteId,
              quotationId: quotationId
            },
            onSuccess: function(response, element, xhr) {
              $(".ui.modal").modal("show")
            }
          });
      })
    },
    init() {
      $calendar.calendar( { type: 'date' } );      
      handler.initSections();
      $(".ui.item-unit.dropdown").dropdown()
    }
  };
  
  quotationInterface.handler = handler;
  handler.init();
}