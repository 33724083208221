window.ordersInterface = {
  handler: {}
};

ordersInterface.ready = function() {
  console.log("Inside orders index interface...");
  var $orderClosedCheckbox          = $(".order.closed.checkbox"),
  
      api_close_order               = 'close order',
      handler
  ;
  
  handler = {
    init() {
      let locale = document.querySelector('meta[name="locale"]').content
      let clientId = $(this).data("client-id")
      let siteId = $(this).data("site-id")
      let quotationId = $(this).data("quotation-id")
      let orderId = $(this).data("order-id")
      
      $orderClosedCheckbox.api({
        action: api_close_order,
        method: 'PUT',
        dataType: "script",
        on: 'change',
        urlData: {
          locale: locale,
          clientId: clientId,
          siteId: siteId,
          quotationId: quotationId,
          orderId: orderId 
        },
        beforeSend: function(settings) {
          settings.data = {
            closed: $(this).checkbox("is checked")
          };
          return settings;
        },
        onSuccess: function(response, element, xhr) {
          $('body').toast({
            message: "Order is closed and client can create new order",
            position: 'top attached',
            class: 'green centered',
            showProgress: 'bottom'
          })
        }
      })      
    }
  };
  
  ordersInterface.handler = handler;
  handler.init();
}