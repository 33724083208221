window.adminInterface = {
  handler: {}
};

adminInterface.ready = function() {
  console.log("Inside admin interface...");
  var $masthead         = $('.masthead'),
      $topMenu          = $('.fixed.menu'),
      $mainSidebar      = $('.ui.sidebar.main.menu'),
      $searchSidebar    = $('.ui.sidebar.search.menu'),
      $dropdown         = $('.ui.dropdown'),
      $table            = $('table'),
      $radioCheckbox    = $('.ui.radio.checkbox'),
      $notifyClient     = $('.notify.client'),
      $modal            = $('.ui.modal'),
      $notificationCart = $('.item.notification.cart'),
  
      api_notify_client_modal = 'notify client modal',
      
      handler
  ;
  
  handler = {
    init: function() {
      // Fix menu when passed
      $masthead.visibility({
        once: false,
        onBottomPassed: function() {
          $topMenu.transition('fade in');
        },
        onBottomPassedReverse: function() {
          $topMenu.transition('fade out');
        }
      });    
      // create sidebar
      $mainSidebar.sidebar('attach events', '.toc.item');
      $searchSidebar.sidebar('attach events', '.search.item');
        
      $dropdown.dropdown();            
      $table.tablesort();
      $radioCheckbox.checkbox();
                
      if (Notification.permission == "default") {
        Notification.requestPermission()
      }
      
      $notifyClient.api({
        action: api_notify_client_modal,
        dataType: "script"
      })
      
      $notificationCart.popup({
        position: 'bottom center',
        variation: 'wide',
        hoverable: true
      });
      
    }
  };
    
  adminInterface.handler = handler;
  handler.init();  
}