import { Controller } from "stimulus"

export default class extends Controller {
  static values = { locale: String }
  static targets = [  "form", 
                      "draft", 
                      "drag",
                      "saveQuotationNumber",
                      "saveQuotationDate",
                      "saveOrderDate"
                   ]
  
  initialize() {
    this.api_save_draft_quotation = 'save draft quotation',
    this.localeValue = document.querySelector('meta[name="locale"]').content
  }
  
  connect() {
    // Create a unique key to store the form data into localStorage.
    // This could be anything as long as it's unique.
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
    this.localStorageKey = window.location
    
    // Retrieve data from localStorage when the Controller loads.
    this.setFormData();
  }
  
  saveToLocalStorage() {
    console.log("auto saving....");
    let $buttonTarget = $(this.draftTarget);
    $buttonTarget.addClass("loading");
    
    const data = this.getFormData();
    localStorage.setItem(this.localStorageKey, JSON.stringify(data));
    
    setTimeout(function() {
      $buttonTarget.removeClass("loading");
    }, 500);
  }
  
  clearLocalStorage() {
    console.log("clear local storage.....")
    // See if there is data stored for this particular form.
    if (localStorage.getItem(this.localStorageKey) != null) {
      // Clear data from localStorage when the form is submitted.
      localStorage.removeItem(this.localStorageKey);
    }
  }
  
  getFormData() {
    // Construct a set of key/value pairs representing form fields and their values.
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData
    const form = new FormData(this.formTarget);
    let data = []
    //loop through each key/value pair.
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/entries#example
    for(var pair of form.entries()) {
      // We don't want to save the authenticity_token to localStorage since that is generated by Rails.
      // https://guides.rubyonrails.org/security.html#cross-site-request-forgery-csrf
      if (pair[0] != "authenticity_token") {
        data.push([pair[0], pair[1]])
      }
    }
    // Return the key/value pairs as an Object. Each key is a field name, and each value is the field value.
    // https://developer.mozilla.org/en-US/docs/Web/Javascript/Reference/Global-Objects/Object/fromEntries
    return Object.fromEntries(data)
  }
  
  setFormData() {
    // See if there is data stored for this particular form.
    if (localStorage.getItem(this.localStorageKey) != null) {
      // We need to convert the String of data back into an Object.
      const data = JSON.parse(localStorage.getItem(this.localStorageKey));
      // This allows us to have access to this.formTarget in the loop below.
      const form = this.formTarget;
      // Loop through each key/value pair and set the value on the corresponding form field.
      Object.entries(data).forEach((entry) => {
        let name = entry[0];
        let value = entry[1];
        let input = form.querySelector(`[name='${name}']`);
        input && (input.value = value);
      })
    }
  }
  
  saveDraft() {
    // Change the behaviour of the button
    console.log("quotation: save draft");
    // addClass("loading")
    let $buttonTarget = $(this.draftTarget);
    $buttonTarget.addClass("loading");
    
    let clientId = $buttonTarget.data("client-id");
    let siteId = $buttonTarget.data("site-id");
    let quotationId = $buttonTarget.data("quotation-id")
    console.log("Client ID: " + clientId + "; Site ID: " + siteId + "; Quotation ID: " + quotationId);
    if (quotationId) {
      // Save remotely
      $buttonTarget
        .api({
          url: '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{id}/save',
          method: 'PUT',
          on: 'now',
          serializeForm: true,
          urlData: {
            locale: this.localeValue,
            client_id: clientId,
            site_id: siteId,
            id: quotationId
          },
          onResponse: function(response) {
            return response;
          },
          successTest: function(response) {
            return response.success || false;
          },
          onSuccess: function(response, element, xhr) {
            // when done, removeClass("loading")
            element.removeClass("loading");
          }
        });
    } else {
      this.saveToLocalStorage();
      $buttonTarget.removeClass("loading");
    }     
    
    $('body')
      .toast({
        horizontal: true,
        position: 'bottom attached',
        message: 'You quotation is saved.',
        showProgress: 'top',
        classProgress: 'green',
        class: 'centered'
      })
  }
 
  saveQuotationNumber() {
    let element = this.saveQuotationNumberTarget
    let $buttonTarget = $(this.saveQuotationNumberTarget);
    $buttonTarget.addClass("loading");
    
    console.log($buttonTarget);
    console.log($(element.parentElement));
    
    let elementValue = $(element.parentElement).find("input[type='text']").val()
    let clientId = $buttonTarget.data("client-id");
    let siteId = $buttonTarget.data("site-id");
    let quotationId = $buttonTarget.data("quotation-id")
    console.log("Client ID: " + clientId + "; Site ID: " + siteId + "; Quotation ID: " + quotationId);

    if (quotationId) {
      // Save remotely
      $buttonTarget
        .api({
          url: '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{id}/save',
          method: 'PUT',
          on: 'now',
          urlData: {
            locale: this.localeValue,
            clientId: clientId,
            siteId: siteId,
            id: quotationId
          },
          data: {
            quotation: {
              number: elementValue
            }
          },
          onResponse: function(response) {
            return response;
          },
          successTest: function(response) {
            return response.success || false;
          },
          onSuccess: function(response, element, xhr) {
            // when done, removeClass("loading")
            element.removeClass("loading");
          }
        });
    } else {  
      this.saveToLocalStorage();                  
      $buttonTarget.removeClass("loading");
    }     

    $('body')
      .toast({
        horizontal: true,
        position: 'bottom attached',
        message: 'You quotation number is saved.',
        showProgress: 'top',
        classProgress: 'green',
        class: 'centered'
      })

  }
    
  saveQuotationDate() {
    let element = this.saveQuotationDateTarget
    let $buttonTarget = $(this.saveQuotationDateTarget);
    $buttonTarget.addClass("loading");
    
    console.log($buttonTarget);
    console.log($(element.parentElement));

    let elementValue = $(element.parentElement).find("input[type='text']").val()
    let clientId = $buttonTarget.data("client-id");
    let siteId = $buttonTarget.data("site-id");
    let quotationId = $buttonTarget.data("quotation-id")
    console.log("Client ID: " + clientId + "; Site ID: " + siteId + "; Quotation ID: " + quotationId);

    if (quotationId) {
      // Save remotely
      $buttonTarget
        .api({
          url: '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{id}/save',
          method: 'PUT',
          on: 'now',
          urlData: {
            locale: this.localeValue,
            clientId: clientId,
            siteId: siteId,
            id: quotationId
          },
          data: {
            quotation: {
              quotation_date: elementValue
            }
          },
          onResponse: function(response) {
            return response;
          },
          successTest: function(response) {
            return response.success || false;
          },
          onSuccess: function(response, element, xhr) {
            // when done, removeClass("loading")
            element.removeClass("loading");
          }
        });
    } else {    
      this.saveToLocalStorage();        
      $buttonTarget.removeClass("loading");
    }     
  
    $('body')
      .toast({
        horizontal: true,
        position: 'bottom attached',
        message: 'You quotation date is saved.',
        showProgress: 'top',
        classProgress: 'green',
        class: 'centered'
      })
 
  }
  
  saveOrderDraft() {
    // Change the behaviour of the button
    console.log("Order: save draft");
    // addClass("loading")
    let $buttonTarget = $(this.draftTarget);
    $buttonTarget.addClass("loading");
    
    let orderId = $buttonTarget.data("order-id");
    let quotationId = $buttonTarget.data("quotation-id")
    console.log("Order ID: " + orderId + "; Quotation ID: " + quotationId);
    if (quotationId) {
      // Save remotely
      $buttonTarget
        .api({
          url: '/{locale}/quotations/{quotation_id}/orders/{id}/save',
          method: 'PUT',
          on: 'now',
          serializeForm: true,
          urlData: {
            locale: this.localeValue,
            quotation_id: quotationId,
            id: orderId
          },
          onResponse: function(response) {
            return response;
          },
          successTest: function(response) {
            return response.success || false;
          },
          onSuccess: function(response, element, xhr) {
            // when done, removeClass("loading")
            this.clearLocalStorage();
            element.removeClass("loading");
          }
        });
    } else {      
      $buttonTarget.removeClass("loading");
    }     
    this.saveToLocalStorage();
    
    $('body')
      .toast({
        horizontal: true,
        position: 'bottom attached',
        message: 'You order is saved.',
        showProgress: 'top',
        classProgress: 'green',
        class: 'centered'
      })
  }  

  saveAdminOrderDraft() {
    // Change the behaviour of the button
    console.log("Order: admin save draft");
    let $buttonTarget = $(this.draftTarget);
    $buttonTarget.addClass("loading");
    
    let clientId = $buttonTarget.data("client-id");
    let siteId = $buttonTarget.data("site-id");
    let quotationId = $buttonTarget.data("quotation-id")
    let orderId = $buttonTarget.data("order-id");
    
    console.log("Order ID: " + orderId + "; Quotation ID: " + quotationId);
    
    if (orderId) {
      // Save remotely
      $buttonTarget
        .api({
          url: '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotation_id}/orders/{id}/save',
          method: 'PUT',
          on: 'now',
          serializeForm: true,
          urlData: {
            locale: this.localeValue,
            client_id: clientId,
            site_id: siteId,
            quotation_id: quotationId,
            id: orderId
          },
          onResponse: function(response) {
            return response;
          },
          successTest: function(response) {
            return response.success || false;
          },
          onSuccess: function(response, element, xhr) {
            element.removeClass("loading");
          }
        });
    } else {      
      $buttonTarget.removeClass("loading");
    }     
    
    $('body')
      .toast({
        horizontal: true,
        position: 'bottom attached',
        message: 'You order is saved.',
        showProgress: 'top',
        classProgress: 'green',
        class: 'centered'
      })
  }  

  saveOrderDate() {
    let element = this.saveOrderDateTarget
    let $buttonTarget = $(this.saveOrderDateTarget);
    $buttonTarget.addClass("loading");
    
    console.log($buttonTarget);
    console.log($(element.parentElement));

    let elementValue = $(element.parentElement).find("input[type='text']").val()
    let quotationId = $buttonTarget.data("quotation-id")
    let orderId = $buttonTarget.data("order-id");

    if (orderId) {
      // Save remotely
      $buttonTarget
        .api({
          url: '/{locale}/quotations/{quotationId}/orders/{orderId}/save',
          method: 'PUT',
          on: 'now',
          urlData: {
            locale: this.localeValue,
            quotationId: quotationId,
            orderId: orderId
          },
          data: {
            order: {
              order_date: elementValue
            }
          },
          onResponse: function(response) {
            return response;
          },
          successTest: function(response) {
            return response.success || false;
          },
          onSuccess: function(response, element, xhr) {
            // when done, removeClass("loading")
            element.removeClass("loading");
          }
        });
    } else {    
      this.saveToLocalStorage();        
      $buttonTarget.removeClass("loading");
    }     
  
    $('body')
      .toast({
        horizontal: true,
        position: 'bottom attached',
        message: 'Your order date is saved.',
        showProgress: 'top',
        classProgress: 'green',
        class: 'centered'
      })
 
  }

}