import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["lineItem"]
  static values = { locale: String,
                    clientId: Number,
                    siteId: Number,
                    quotationId: Number,
                    sectionId: Number
                  }

  initialize() {
    this.localeValue = document.querySelector('meta[name="locale"]').content
    console.log("Locale: " + this.localeValue + " | Client ID: " + this.clientIdValue + " | Site ID: " + this.siteIdValue + " | Quotation ID: " + this.quotationIdValue);    
  }
  
  connect() {
    this.sortable = Sortable.create(this.element, {
      sort: true,
      // animation: 0, // ms, animation speed moving items when sorting, `0` — without animation
      handle: ".ui.icon.button", // Restricts sort start click/touch to the specified element
      draggable: "tr", // Specifies which items inside the element should be sortable,
      dragClass: "item",
      // group: "tables",
      onEnd: this.end.bind(this)
    })
  }
  
  end(event) {
    console.log("Old index: " + event.oldIndex + " --> New index: " + event.newIndex + "; Old draggable index: " + event.oldDraggableIndex + " --> New draggable index: " + event.newDraggableIndex);
    console.log(event)    
    let id = event.item.dataset.id
    let position = event.newDraggableIndex + 1    

    // ## Save remotely ##
    let $buttonTarget = $(this.lineItemTarget);
    $buttonTarget
      .api({
        url: '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/line_items/{id}/move',
        method: 'PATCH',
        on: 'now',
        urlData: {
          locale: this.localeValue,
          clientId: this.clientIdValue,
          siteId: this.siteIdValue,
          quotationId: this.quotationIdValue,
          id: id
        },
        data: {
          position: position
        },
        onResponse: function(response) {
          return response;
        },
        successTest: function(response) {
          return response.success || false;
        },
        onSuccess: function(response, element, xhr) {
          // when done, removeClass("loading")
        }
      });    
  }
}