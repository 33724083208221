window.clientsController = {
  handler: {}
};

clientsController.ready = function(errorMessages) {
  var locale,
      messages                  = errorMessages,
      defaultCountryId          = 90, // Hong Kong
      api_list_clients          = 'list clients',
      api_list_countries        = 'list countries', 
      $objectResult             = $("#objects-result"),
      $client_country_dropdown  = $(".ui.countries.dropdown"),
      $client_state_dropdown    = $(".ui.states.dropdown"),      
      handler
  ;
  
  handler = {
    init_country_dropdown: function() {
      if ($client_country_dropdown.length) {
        $client_country_dropdown
          .dropdown({
            onChange: function(value, text, $choice) {
              handler.set_state_dropdown(value);
            }
          })
          .api({
            action: 'list countries',
            urlData: {
              locale: locale
            },
            on: 'now',
            beforeXHR: function(xhr) {
              var token = $("meta[name='tokie']").attr("content");
              console.log
              xhr.setRequestHeader('Authorization', `${token}`);
              return xhr;            
            },            
            onSuccess: function(response) {
              $(this).dropdown("setup menu", { values: response.results });
              var countryId = $client_country_dropdown.data("country-id");
              if (countryId > 0) {                
                $(this).dropdown("set selected", countryId);
                // This should not be called
                // However, due to the first time loading the Edit Client page,
                // onChange event from "set selected" is not called. 
                // The following line is manually triggered.
                handler.set_state_dropdown(countryId);
              } else {
                $(this).dropdown("set selected", defaultCountryId);
              }
            }
          });        
      }
    },
    set_state_dropdown: function(selectedCountry) {
      console.log(`Selected country: ${selectedCountry}`)
      if (selectedCountry) {
        $client_state_dropdown
          .dropdown()
          .api({
            action: 'search states',
            urlData: {
              locale: locale,
              countryId: selectedCountry
            },
            on: 'now',
            beforeXHR: function(xhr) {
              var token = $("meta[name='tokie']").attr("content");
              console.log
              xhr.setRequestHeader('Authorization', `${token}`);
              return xhr;            
            },            
            onSuccess: function(response, element, xhr) {
              $(this).dropdown("clear");

              var results = response.results;
              if (response.results.length > 0) {
                var defaultOption = { value: "", text: messages.default_state_option, name: messages.default_state_option };
                results = response.results;
                results.unshift(defaultOption);                
              } else {                
                results = [ { value: "", text: messages.no_state_available, name: messages.no_state_available } ];                
              }
              
              $(this).dropdown("setup menu", { values: results });
              
              var stateId = $(this).data("state-id");
              if (stateId > 0) {
                $(this).dropdown("set selected", stateId, null, true);
              } else {
                $(this).dropdown("set selected", "", null, true);  
              }              
            }
          });
                  
      }
    },
    refresh_clients: function() {
      if ($objectResult.length) {
        $objectResult
          .api({
            action: api_list_clients,
            on: 'now',
            urlData: {
              locale: locale
            },
            beforeXHR: function(xhr) {
              var token = $("meta[name='tokie']").attr("content");
              console.log(`Token: ${token}`)
              xhr.setRequestHeader('Authorization', `${token}`);
              return xhr;            
            },            
            onResponse: function(response) {
              return response;
            },
            successTest: function(response) {
              return response.success || false;
            },
            onSuccess: function(response, element, xhr) {
              element.html("");
              data = response.results;
              if (data.length > 0) {
                for (var i = 0; i < data.length; i++) {
                  var content = "";
                  content += `<tr class='activator-${data[i].id} object-item' data-id='${data[i].id}'>`;
                  content += "  <td class='two wide left aligned collapsing full-name' data-label='Name'>";
                  content += `    <a href='${data[i].object_url}'><i class='user icon'></i>&nbsp;${data[i].name}</a></td>`;
                  content += "  <td class='two wide left aligned collapsing chinese-name' data-label='Chinese Name'>";
                  content += `    <i class='user icon'></i>&nbsp;${data[i].chinese_name}</td>`;
                  content += "  <td class='eight wide left aligned' data-label='Address'>";
                  content += `    <i class='address card outline icon'></i>&nbsp;${data[i].address}</td>`;
                  content += `  <td class='left aligned' data-label='Phone'>${data[i].phone}</td>`;
                  content += `  <td class='left aligned' data-label='Phone'>${data[i].fax}</td>`;
                  content += `  <td class='two wide center aligned' data-label='Sites'>${data[i].number_of_construction_sites}</td>`;
                  content += "  <td class='one wide center aligned' colspan='2'>";
                  content += "    <div class='ui icon buttons'>";
                  content += `      <a class='ui yellow edit client button' href='${data[i].edit_object_url}' data-url='${data[i].edit_object_url}'><i class='edit icon'></i></a>`; 
                  content += `      <button class='ui red delete client button' data-url='${data[i].delete_object_url}'><i class='trash icon'></i></button></div></td>`; 
                  content += "</tr>";
              
                  element.append(content);
                }              
              } else {
                var content = "<tr><td colspan='3'>" + response.message + "</td></tr>";
                element.append(content);
              }    
            }
          });        
      }

    },
    init: function() {
      locale = $("meta[name='locale']").attr("content");
      handler.refresh_clients();
      handler.init_country_dropdown();
    }
  };
  
  clientsController.handler = handler;
  handler.init();  
}
