import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'jquery/src/jquery'
import 'jquery-tablesort/jquery.tablesort.min.js'
import 'form-serializer/jquery.serialize-object.js'
import 'semantic/dist/semantic.js'

require('stylesheets/application.scss')

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "common"
import "admin"
import "controllers"
