$.fn.api.settings.api = {
  'api authenticate' : '/api/authenticate',
  'list admins' : '/{locale}/api/v1/admin/administrators',
  'list clients' : '/{locale}/api/v1/admin/clients',
  'list countries': '/{locale}/api/v1/admin/countries',
  'search states': '/{locale}/api/v1/admin/states?country_id={countryId}',
  'save draft quotation': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{id}',
  'save quotation date': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{id}',
  'prepare reject order': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/orders/{orderId}/prepare_reject',
  'prepare reject quotation': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/prepare_reject',
  'notify client modal': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{id}/notify',
  'notify client action': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{id}/notify_recipients',
  'prepare rename section': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/sections/{sectionId}/prepare_rename',
  'new section': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/sections/new',
  'delete section': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/sections/{sectionId}',
  'new variant': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/sections/{sectionId}/variants/new',
  'update preferred contact': '/{locale}/api/v1/admin/clients/{clientId}/sites/{siteId}/contacts/{id}/preferred',
  'close order': '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/orders/{orderId}/closed'
}