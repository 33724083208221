import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static values = { locale: String,
                    administratorId: Number,
                    clientId: Number,
                    siteId: Number,
                    id: Number
                  }
  static targets = [
                      "form", 
                      "notify", 
                      "addFiveLineItem",
                      "addTenLineItem",
                      "addTwentyLineItem"
                   ]
  
  initialize() {
    this.api_quotation_status = '/{locale}/api/v1/admin/clients/{client_id}/sites/{site_id}/quotations/{quotation_id}/status?uid={uid}'    
    this.localeValue = document.querySelector('meta[name="locale"]').content
    this.administratorIdValue = document.querySelector('meta[name="uid"]').content
  }
  
  connect() {
    this.subscription = consumer.subscriptions.create( { channel: "AdministratorQuotationChannel", id: this.idValue }, {
      connected: this._connected.bind(this),
      disconnected: this._disconnected.bind(this),
      received: this._received.bind(this)
    });
    this.checkQuotationStatus();
  }
  
  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
  
  _connected() {
    
  }
  
  _disconnected() {
    
  }
  
  _received(data) {
    console.log("Quotation controller received a message....");
    if (data.event == "unlock") {
      if ($(".ui.quotation.staff.container")) {
        $(".ui.quotation.staff.container").append(data.message);
        $(".ui.unlock.notification.modal")
          .modal({
            onApprove: function() {
              console.log(data.actions.approve.url);
              window.location.href = data.actions.approve.url;
            }
          }).modal("show");        
      } else {
        $('body').toast({
          displayTime: 0,
          title: data.title,
          message: data.content,
          classActions: 'bottom attached',
          actions: [{
            text: data.actions.approve.label,
            class: 'green',
            click: function() {
              window.location.href = data.actions.approve.url
            }
          }, {
            text: data.actions.cancel.label,
            class: 'red',
            click: function() {}
          }]
        })        
      }  
    } else if (data.event == "request_unlock") {
      if ($(".ui.quotation.staff.container")) {
        $(".ui.quotation.staff.container").append(data.message);
        $(".ui.request.unlock.modal").modal("show");
      } else {
        $('body').toast({
          displayTime: 0,
          title: data.title,
          message: data.content,
          classActions: 'bottom attached',
          actions: [{
            text: data.actions.approve.label,
            class: 'green',
            click: function() {
              window.location.href = data.actions.approve.url
            }
          }]
        });          
      }
      
      if ($(".ui.quotation.approver.container")) {
        $(".unlock.button").prop("disabled", false);
      }      
    }
  }

  checkQuotationStatus() {
    if ($(".ui.quotation.container")) {
      $('body')
        .api({
          url: this.api_quotation_status,
          method: 'GET',
          on: 'now',
          urlData: {
            locale: this.localeValue,
            client_id: this.clientIdValue,
            site_id: this.siteIdValue,
            quotation_id: this.idValue,
            uid: this.administratorIdValue
          },
          beforeXHR: function(xhr) {
            var token = $("meta[name='tokie']").attr("content");
            console.log(`quotation_controller.checkQuotationStatus --> ${token}`)
            xhr.setRequestHeader('Authorization', `${token}`);
            return xhr;            
          },            
          onResponse: function(response) {
            return response;
          },
          successTest: function(response) {
            return response.success || false;
          },
          onSuccess: function(response, element, xhr) {
            if (response.results.lock) {
              console.log(response.results);
              let form = document.querySelector('form#quotation-form');
              
              // Disable all form elements
              Array.from(form.elements).forEach(formElement => formElement.disabled = true);
              // Disable dropdown menu
              $(".unit.menu > .item").addClass("disabled")
              $(".add-line-item.menu > .item").addClass("disabled")
              
              $(".ui.nag div.title").html(response.results.message.title);
              $(".ui.nag div.content").html(response.results.message.content);
              $(".ui.nag").nag( { persist: true } );
              
              $(".request-unlock.button").prop("disabled", false);
              if (response.results.allow_unlock) {
                $(".unlock.button").prop("disabled", false);
              }
            }
          }
        });      
    }
  }

  request_unlock() {
    console.log("Request unlock quotation...");
    this.subscription.perform("request_unlock")
  }

  unlock() {
    console.log("Unlock client quotation.");
    this.subscription.perform("unlock")
  }
  
  addFive() {
    const element = this.addFiveLineItemTarget
    this.add(element)
  }
  
  addTen() {
    const element = this.addTenLineItemTarget
    this.add(element)    
  }

  addTwenty() {
    const element = this.addTwentyLineItemTarget
    this.add(element)    
  }
  
  add(element) {
    console.log("Add line item...")
    let $parentMenu = $("div#quotation-add-line-item-menu")
    $parentMenu.addClass("loading")
    
    let numOfRows = element.dataset.row
    let sectionId = $(".ui.quotation.tabular.menu .active.item").data("section-id")
    
    console.log("Line item: " + this.idValue)
    console.log("Number of row: " + numOfRows)
    
    $parentMenu
      .api({
        url: '/{locale}/admin/clients/{clientId}/sites/{siteId}/quotations/{quotationId}/sections/{sectionId}/add_after',
        method: 'POST',
        dataType: "script",
        on: 'now',
        urlData: {
          locale: this.localeValue,
          clientId: this.clientIdValue,
          siteId: this.siteIdValue,
          quotationId: this.idValue,
          sectionId: sectionId
        },
        data: {
          num: numOfRows
        },
        onResponse: function(response) {
          return response;
        },
        successTest: function(response) {
          return response.success || false;
        },
        onComplete(response, element, xhr) {
          element.removeClass("loading");
        },
        onSuccess: function(response, element, xhr) {
        },
        onError: function(errorMessage, element, xhr) {
          alert(errorMessage);
        }
      });
  }

}