import { Controller } from "stimulus"

export default class extends Controller {
  static values = { locale: String,
                    clientId: Number,
                    siteId: Number,
                    quotationId: Number,
                    id: Number,
                    administratorId: Number
                  }
  static targets = [
                      "form", 
                      "notify", 
                   ]
  
  initialize() {
    this.api_order_status = '/{locale}/api/v1/admin/clients/{client_id}/sites/{site_id}/quotations/{quotation_id}/orders/{order_id}/status?uid={uid}'        
    this.localeValue = document.querySelector('meta[name="locale"]').content
    this.administratorIdValue = document.querySelector('meta[name="uid"]').content
    this.checkOrderStatus();
  }
  
  checkOrderStatus() {
    console.log("AdminOrder..... check order status....");
    if ($(".ui.order.container")) {
      $('body')
        .api({
          url: this.api_order_status,
          method: 'GET',
          on: 'now',
          urlData: {
            locale: this.localeValue,
            client_id: this.clientIdValue,
            site_id: this.siteIdValue,
            quotation_id: this.quotationIdValue,
            order_id: this.idValue,
            uid: this.administratorIdValue
          },
          beforeXHR: function(xhr) {
            var token = $("meta[name='tokie']").attr("content");
            xhr.setRequestHeader('Authorization', `${token}`);
            return xhr;            
          },                      
          onResponse: function(response) {
            return response;
          },
          successTest: function(response) {
            return response.success || false;
          },
          onSuccess: function(response, element, xhr) {
            if (response.results.lock) {
              console.log(response.results);
              let form = document.querySelector('form#order-form');
              
              // Disable all form elements
              Array.from(form.elements).forEach(formElement => formElement.disabled = true);
              
              $(".ui.nag div.title").html(response.results.message.title);
              $(".ui.nag div.content").html(response.results.message.content);
              $(".ui.nag").nag( { persist: true } );
              
              $(".request-unlock.button").prop("disabled", false);
              if (response.results.allow_unlock) {
                $(".unlock.button").prop("disabled", false);
              }
            }
          }
        });      
    }
  }

}