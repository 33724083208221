window.adminsController = {
  handler: {}
};

adminsController.ready = function() {
  var api_list_admins = 'list admins',
      $adminResult    = $("#administrators-result"),
      handler
  ;
  
  handler = {
    init: function() {
      $adminResult
        .api({
          action: api_list_admins,
          on: 'now',
          beforeXHR: function(xhr) {
            var token = $("meta[name='tokie']").attr("content");
            console.log(`quotation_controller.checkQuotationStatus --> ${token}`)
            xhr.setRequestHeader('Authorization', `${token}`);
            return xhr;            
          },            
          onResponse: function(response) {
            return response;
          },
          successTest: function(response) {
            return response.success || false;
          },
          onSuccess: function(response, element, xhr) {
            element.html("");
            data = response.results;
            for (var i = 0; i < data.length; i++) {
              var content = "";
              content += "<tr class='activator-" + data[i].id + " user-item' data-id='" + data[i].id + "'>";
              content += "  <td class='four wide left aligned collapsing full-name' data-label='Name'>";
              content += "    <a href='" + data[i].object_url + "'><i class='user icon'></i>&nbsp;" + data[i].full_name + "</a></td>";
              content += "  <td class='five wide left aligned' data-label='Email'>";
              content += "    <i class='envelope icon'></i>&nbsp;" + data[i].email + "</td>";
              content += "  <td class='three wide left aligned' data-label='Role'>" + data[i].role + "</td>";
              content += "  <td class='two wide left aligned' data-label='Current Sign In'>" + data[i].current_ip + "</td>";
              content += "  <td class='two wide left aligned' data-label='Last Sign In'>" + data[i].last_ip + "</td>";
              content += "</tr>";
              
              element.append(content);
            }
          }
        });
    }
  };
  
  adminsController.handler = handler;
  handler.init();
  
}
