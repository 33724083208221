import { Sortable, MultiDrag } from "sortablejs"

window.sortable = {
  handler: {}
};

sortable.ready = function() {
  console.log("Inside sortable...");
  var $tbodies       = $('table.reorderable').find("tbody"),
      handler
  ;
  
  handler = {
    swap: function(oldItem, targetItem) {
      var oldId = oldItem[0].dataset.id;
      var oldIndex = oldItem[0].dataset.index;
      
      var newId = targetItem[0].dataset.id;
      var newIndex = targetItem[0].dataset.index;
      
      var oldItemPosition = oldItem.find("input[name='quotation[line_items_attributes]["+oldIndex+"][position]'").val();
      var newItemPosition = targetItem.find("input#quotation_line_items_attributes_" + newIndex + "_position").val();
      
      console.log("Old Id: " + oldId + "--> " + oldItemPosition + "; New Id: " + newId + "--> " + newItemPosition);

      oldItem[0].dataset.index = newIndex;
      oldItem.find("input[name='quotation[line_items_attributes]["+oldIndex+"][position]'").val(newItemPosition);
      oldItem.find("span.item-indice").html(newItemPosition);

      targetItem[0].dataset.index = oldIndex;
      targetItem.find("input[name='quotation[line_items_attributes]["+newIndex+"][position]'").val(oldItemPosition);
      targetItem.find("span.item-indice").html(oldItemPosition);      
    },
    init: function() {
      Sortable.mount(new MultiDrag());
      $.each($tbodies, function(index, element) {
        Sortable.create(element, {
          animation: 0, // ms, animation speed moving items when sorting, `0` — without animation
          handle: ".ui.icon.button", // Restricts sort start click/touch to the specified element
          selectedClass: "warning",
          multiDrag: true,
          draggable: "tr", // Specifies which items inside the element should be sortable,
          group: "tables",
          onEnd: function(evt /**Event*/ ) {            
            $tbodies.each(function(i, el) {
            	var $el = $(el);              
            	if($el.children().length == 0) {
              	$el.addClass("empty-placeholder");
              } else {
              	$el.removeClass("empty-placeholder");
              }
            });
            
            var itemElement = evt.item;
            // console.log(itemElement);
            console.log("Old index: " + evt.oldIndex + " --> New index: " + evt.newIndex + "; Old draggable index: " + evt.oldDraggableIndex + " --> New draggable index: " + evt.newDraggableIndex);
            var oldDraggableIndex = evt.oldDraggableIndex;
            var targetDraggableIndex = evt.newDraggableIndex;
            var oldItem = $('table.reorderable tbody tr').eq(oldDraggableIndex);
            var targetItem = $('table.reorderable tbody tr').eq(targetDraggableIndex);
            console.log(oldItem);
            console.log(targetItem);
            handler.swap(oldItem, targetItem);
          }
        });
      })      
    }
  };
  
  sortable.handler = handler;
  handler.init();
}
